var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import get from 'lodash/get';
import { isAPIGitLinked } from '@postman-app-monolith/renderer/api-dev/interfaces/APIInterface';
import { launchDarkly } from '@postman-app-monolith/renderer/onboarding/src/common/LaunchDarkly';
import NavigationService from '@postman-app-monolith/renderer/js/services/NavigationService';
import { SHOW_REQUEST_SECURITY_WARNINGS_COUNT } from '@postman-app-monolith/renderer/js/constants/SettingsGeneralConstants';
import { SECURITY_WARNINGS_COUNT_LD_FEATURE_FLAG, API_SECURITY_FEATURE_FLAG } from '../constants';
const context = pm.logger.getContext('request-security-overview', 'api-governance');
class SecurityOverviewService {
    isSecurityOverviewEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { apiId } = NavigationService.getCurrentRouteParams();
                if (apiId && (yield isAPIGitLinked(apiId))) {
                    // disabling security overview for git linked APIs
                    return Promise.resolve(false);
                }
                return this.isFeatureEnabled();
            }
            catch (error) {
                pm.logger.info('SecurityOverviewService~isSecurityOverviewEnabled~Unable to fetch feature enabled status', error, {
                    context,
                });
                return Promise.resolve(false);
            }
        });
    }
    isFeatureEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // @ts-ignore
                yield pm.billing.onPlanFeaturesLoad();
                // @ts-ignore
                const featureServiceFlag = get(pm.billing, `plan.features.${API_SECURITY_FEATURE_FLAG}.value`, false);
                // const launchDarklyFlag = launchDarkly.getFlag(SECURITY_OVERVIEW_LD_FEATURE_FLAG, false);
                return featureServiceFlag;
            }
            catch (error) {
                pm.logger.info('SecurityOverviewService~isFeatureEnabled~Unable to fetch the feature status', error, { context });
                return false;
            }
        });
    }
    showSecurityWarningsCount() {
        const securityWarningsCountSetting = pm.settings.getSetting(SHOW_REQUEST_SECURITY_WARNINGS_COUNT);
        // if the user config is defined then use that
        // otherwise use the feature flag value
        if (typeof securityWarningsCountSetting === 'boolean') {
            return securityWarningsCountSetting;
        }
        return launchDarkly.getFlag(SECURITY_WARNINGS_COUNT_LD_FEATURE_FLAG, false);
    }
}
export default new SecurityOverviewService();
