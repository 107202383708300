import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  IllustrationNoIntegrations,
  IllustrationInternalServerError,
  BlankState,
  Spinner,
  Flex,
  toast,
  Heading,
  Text
} from '@postman/aether';
import { NEW_RELIC_SERVICE_ID } from '@postman-app-monolith/renderer/integrations/monitoring/constants';
import { validateNewRelicApiKey } from '@postman-app-monolith/renderer/integrations/components/TokenManager/TokenManagerUtils';

// Services
import OnPremTokenService from '@@renderer/integrations/git/services/OnPrem/OnPremTokenService';
import OnPremRemoteGitService from '@@renderer/integrations/git/services/OnPrem/OnPremRemoteGitService';
import AuthenticationInstanceService from '@@renderer/integrations/services/AuthenticationInstanceService';

// Components
import TokenTable from '@postman-app-monolith/renderer/integrations/components/TokenManager/Table';

// Utils
import { hydrateAuthLinkedAPI } from '@postman-app-monolith/renderer/integrations/git/stores/helpers';

const showErrorToast = (err, message) => {
    return toast({
      status: 'error',
      description: _.get(err, 'error.message', message)
    });
  };

class TokenManagerContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      tokenList: [],
      loadingAuthInstances: false,
      errorAuthInstances: false
    };
  }

  componentDidMount () {
    this.fetchTokens();
  }

  /**
   * Fetches the personal access tokens of on-prem desktop integrations.
   */
  fetchOnPremGitTokens () {
    const onPremGitTokens = OnPremTokenService.getTokens(),
      onPremGitTokenList = _.map(onPremGitTokens, (token) => {
        return {
          id: token.id,
          service: token.service,
          accessToken: token.accessToken,
          customDomain: token.customDomain,
          type: token.type,
          user: token.user,
          updateToken: this.updateToken,
          deleteToken: this.deleteToken
        };
      });

    return onPremGitTokenList;
  }

  /**
   * Fetches team-scoped authentication instances of allowed authentication classes
   * to be shown in the settings UI.
   */
  fetchAuthInstances () {
    return AuthenticationInstanceService.listAuthenticationInstancesForSettingsPage()
      .then(({ body }) => {
        const authInstances = body.data,
          authInstanceList = _.map(authInstances, (authInstance) => {
            return {
              id: authInstance.id,
              service: authInstance.service,
              authenticationClass: authInstance.authenticationClass,
              data: authInstance.data,
              scopes: authInstance.scopes,

              // Server never returns sensitive data
              accessToken: '',
              updateToken: this.updateAuthInstance,
              deleteToken: this.deleteAuthInstance
            };
          });

        return Promise.resolve(authInstanceList);
      })
      .catch((err) => {
        pm.logger.error('TokenManagerContainer ~ fetchAuthInstances : Failed to fetch authentication instances', err);
        return Promise.reject(err);
      });
  }

  /**
   * Fetch all the tokens across different auth category. Currently it fetches Git enterprise tokens and
   * team-scoped authentication instances for allowed authentication classes.
   */
  fetchTokens () {
    const onPremGitTokenList = this.fetchOnPremGitTokens();

    this.setState({
      loadingAuthInstances: true,
      errorAuthInstances: false
    });

    return this.fetchAuthInstances()
      .then((authenticationInstanceList) => {
        this.setState({
          tokenList: _.concat(onPremGitTokenList, authenticationInstanceList),
          loadingAuthInstances: false
        });
      })
      .catch(() => {
        this.setState({
          tokenList: [],
          loadingAuthInstances: false,
          errorAuthInstances: true
        });
      });
  }

  /**
   * Method to update the token details for the given service and the domain in local storage.
   * @param {Object} auth The token details to be updated.
   * @param {String} auth.accessToken The access token that needs to be updated.
   * @param {String} auth.service The service of the token.
   * @param {String} auth.customDomain The domain for which the token is issued.
   *
   * @returns {Object} {status, reason} explaining the validation status and the reason if it failed
   */
  updateToken = async (auth) => {
    try {
      const { id, accessToken, customDomain, service } = auth,
        isAuthValid = await OnPremRemoteGitService.isAuthValid({ accessToken, customDomain, service });

      if (isAuthValid) {
        await OnPremTokenService.updateToken(id, accessToken);

        // Fetches the updated tokens and re-renders the table
        this.fetchTokens();
        return { status: true };
      }
      else {
        return {
          status: false,
          reason: 'invalidToken'
        };
      }
    }
    catch (err) {
      pm.logger.error('TokenManagerContainer ~ updateToken : Failed to update token', err);
      return {
        status: false,
        reason: 'serverUnreachable'
      };
    }
  }

  /**
   * Method to delete the access token from the local storage.
   * This can be extended to delete tokens from server for user-auth.
   *
   * @param {Object} auth The criteria/identifiers to identify the token to be deleted.
   * @param {String} auth.id The UUID of the token in the local storage.
   */
  deleteToken = async (auth) => {
    try {
      const { id } = auth;
      await OnPremTokenService.deleteToken(id);

      // Fetches the updated tokens and re-renders the table
      this.fetchTokens();
    }
    catch (err) {
      pm.logger.error('TokenManagerContainer ~ deleteToken : Failed to delete token', err);
    }
  }

  /**
   * Method to update the sensitive data fields of authentication instance of given authentication class.
   *
   * @note Please refer to AUTHENTICATION_CLASSES_SENSITIVE_DATA_KEY_MAP inside AuthenticationInstanceService for
   * data fields which gets updated.
   *
   * @param {Object} auth The authentication instance details to be updated.
   * @param {String} auth.accessToken The sensitive data that needs to be updated.
   * @param {String} auth.authenticationClass The authentication class
   *
   * @returns {Object} {status, reason} explaining the validation status and the reason if it failed
   */
    updateAuthInstance = async (auth) => {
      try {
        const { id, accessToken, authenticationClass, data, service } = auth,
          sensitiveKey = AuthenticationInstanceService.AUTHENTICATION_CLASSES_SENSITIVE_DATA_KEY_MAP[authenticationClass],
          payload = {
            data: {
              [sensitiveKey]: accessToken
            }
          },
          isAuthValid = await this.isAuthInputValid(accessToken, data, authenticationClass, service);

        if (isAuthValid) {
          await AuthenticationInstanceService.updateAuthenticationInstance(id, payload);

          // Fetches the updated tokens and re-renders the table
          this.fetchTokens();
          return { status: true };
        } else {
          return {
            status: false,
            reason: 'invalidToken'
          };
        }
      }
      catch (err) {
        pm.logger.error('TokenManagerContainer ~ updateAuthInstance : Failed to update authentication instance', err);
        return { status: false };
      }
    }

    /**
     * Method to delete the authentication instance.
     *
     * @param {Object} auth
     * @param {String} auth.id The authentication instance ID
     */
    deleteAuthInstance = async (auth) => {
      const { t } = this.props;

      try {
        const { id, service } = auth;
        await AuthenticationInstanceService.deleteAuthenticationInstance(id);

        // Fetches the updated tokens and re-renders the table
        this.fetchTokens()
          .then(() => {
            // Check if we have any tokens present for the given service
            const noTokensPresent = !_.find(this.state.tokenList, { service });

            // We need to hydrate the Git linked APIs again
            hydrateAuthLinkedAPI({ updatedService: service, noTokensPresent });
          });
      } catch (err) {
        showErrorToast(err, t('integrations:token_manager.delete_auth_instance_error'));
        pm.logger.error('TokenManagerContainer ~ deleteAuthInstance : Failed to delete authentication instance', err);
      }
    }

    /**
     * Checks if the auth input given by user when trying to edit is valid
     *
     * @param {string} accessToken - The user provided new access token
     * @param {{ [key: string]: string | object }} data - the additional data required to perform the validation. For ex-
     * New relic needs region along with the access token to validate the user.
     * @param {string} authenticationClass - The authentication class of the shared auth
     * @param {string} service - The service of the authentication class
     * @returns - Boolean value. True if the auth is valid.
     */
    isAuthInputValid = async (accessToken, data, authenticationClass, service) => {
      const { t } = this.props;

      switch (service) {
        case NEW_RELIC_SERVICE_ID:
          const isValid = await validateNewRelicApiKey(accessToken, data, authenticationClass, service);

          return isValid;
        default:
            showErrorToast(null, t('integrations:token_manager.auth_instance_not_valid_error'));
            return false;
      }
    }

  render () {
    const { t } = this.props;

    const { tokenList, errorAuthInstances, loadingAuthInstances } = this.state,
      { view } = this.props;

    if (loadingAuthInstances) {
      return (
        <Flex direction='column' alignItems='center' justifyContent='center' padding='spacing-xxxl' gap='spacing-s'>
          <Spinner />
          <Text>{t('integrations:token_manager.loading_state')}</Text>
        </Flex>
      );
    }

    if (errorAuthInstances) {
      return (
        <BlankState
          title={t('integrations:token_manager.error_state.title')}
          description={t('integrations:token_manager.error_state.desc')}
        >
          <IllustrationInternalServerError />
        </BlankState>
      );
    }

    // @todo: Update the learning center link for connected account management
    if (_.isEmpty(tokenList)) {
      return (
        <Flex alignItems='center' height='100%'>
          <BlankState
            title={t('integrations:token_manager.empty_state.title')}
            description={t('integrations:token_manager.empty_state.desc')}
          >
            <IllustrationNoIntegrations />
          </BlankState>
        </Flex>
      );
    }

    /**
     * The current component is re-used in both the settings page and the settings modal.
     * Header for the settings page is controlled through a manifest but settings modal requires it to be provided in the container.
     * @todo: Remove the view prop after the completion of unified settings intiative.
     */
    if (view === 'settingsModal') {
      return (
        <Flex direction='column' gap='spacing-xl'>
          <Heading text={t('integrations:token_manager.settings_modal.page_heading')} type='h2' />
          <Flex direction='column' gap='spacing-s'>
            <Heading text={t('integrations:token_manager.settings_modal.third_party_accounts_heading')} type='h4' />
            <TokenTable tokenList={tokenList} />
          </Flex>
        </Flex>
      );
    }

    return (
      <TokenTable tokenList={tokenList} />
    );
  }
}

TokenManagerContainer.propTypes = {
  view: PropTypes.string
};

export default withTranslation('integrations')(TokenManagerContainer);
